import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';

import { AppTopBarComponent } from './components/app.topbar.component';
import { PortalLayoutComponent } from './components/portal.layout.component';
import { AppLayoutComponent } from './components/app.layout.component';
import { PortalProgressLayoutComponent } from './components/progress.layout.component';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  declarations: [
    AppTopBarComponent,
    AppLayoutComponent,
    PortalLayoutComponent,
    PortalProgressLayoutComponent
  ],
  imports: [
    FormsModule,
    ButtonModule,
    CommonModule,
    MenuModule, 
    RouterModule,
    ProgressBarModule,
    DropdownModule
  ],
  exports: [AppLayoutComponent, PortalLayoutComponent]
})
export class AppLayoutModule { }
