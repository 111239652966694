import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { UserMeDto } from '../features/shared/api/user-details.model';
import { UserDetailsService } from '../features/shared/api/user-details.service';

@Directive({
  selector: '[npsPermission]'
})
export class NpsPermissionDirective implements OnInit, OnDestroy {
  @Input() set npsPermission(config: { npsPermission: string; npsUser$?: Observable<UserMeDto | undefined> }) {
    this.npsPermissionString = config.npsPermission;
    this.npsUser$ = config.npsUser$;
  }

  npsPermissionString: string = ''; // endpoint name
  npsUser$?: Observable<UserMeDto | undefined>;
  private visible = false;

  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserDetailsService,
  ) {}

  ngOnInit() {
    //subscribe to user permissions

    if (this.npsUser$) {
      this.npsUser$
        .pipe(filter(p => p != undefined))
        .subscribe(user =>
          this.userService.hasPermission(this.npsPermissionString, user?.roles)
          .subscribe(result => this.updateView(result)));
    }
  }

  ngOnDestroy() {
    this.clearView();
  }

  private updateView(show: boolean) {
    if (show && !this.visible) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    if (!show && this.visible) {
      this.clearView();
    }
    this.visible = show;
  }

  private clearView() {
    this.viewContainer.clear();
  }
}
