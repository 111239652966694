<h1 class="text-center mt-5">NPS: Network Provisioning Suite</h1>

<div *ngIf="!(userArrived$ | async);else userIsThere" class="flex justify-content-center align-items-center" style="height: 40vh">
  <p-progressSpinner></p-progressSpinner>
  <h5 class="m-5">Initializing layout...</h5>
</div>

<ng-template #userIsThere>
  <div class="px-4 py-5 md:px-6 lg:px-8">
    <div class="grid">

      <a class="col-12 md:col-6 lg:col-4" routerLink="./projects/list" *npsPermission="{npsPermission: 'ProjectGetList', npsUser$: npsUser$}">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Projects</span>
              <div class="text-900 font-medium text-xl">The list of project profiles</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-cog text-blue-500 text-xl"></i>
            </div>
          </div>
        </div>
      </a>

      <a class="col-12 md:col-6 lg:col-4" routerLink="./projects/users" *npsPermission="{npsPermission: 'UserProjectMappingGetList', npsUser$: npsUser$}">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">User Projects Mappings</span>
              <div class="text-900 font-medium text-xl">The list of user project mappings</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-users text-green-500 text-xl"></i>
            </div>
          </div>
        </div>
      </a>

      <a class="col-12 md:col-6 lg:col-4" routerLink="./network-requests/list" *npsPermission="{npsPermission: 'OpNetworkRequestGetList', npsUser$: npsUser$}">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Network Requests</span>
              <div class="text-900 font-medium text-xl">The list of network requests</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-sitemap text-gray-500 text-xl"></i>
            </div>
          </div>
        </div>
      </a>

      <a class="col-12 md:col-6 lg:col-4" routerLink="./project-spokes" *npsPermission="{ npsPermission: 'GpProjectSpokesGetList', npsUser$: npsUser$ }">
        <!-- TODO snp permission -->
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Project Spokes</span>
              <div class="text-900 font-medium text-xl">Hubs, Project Spokes and Spoke Requests</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-link text-gray-500 text-xl"></i>
            </div>
          </div>
          <!--<span class="text-green-500 font-medium">%52+ </span>
        <span class="text-500">since last week</span>-->
        </div>
      </a>

      <a class="col-12 md:col-6 lg:col-4" routerLink="./firewall/rules/list" *npsPermission="{ npsPermission: 'GpFirewallProjectRulesGetList', npsUser$: npsUser$ }">
        <!-- TODO snp permission -->
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Firewall</span>
              <div class="text-900 font-medium text-xl">Firewall Rules and Requests</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-shield text-red-500 text-xl"></i>
            </div>
          </div>
          <!--<span class="text-green-500 font-medium">%52+ </span>
        <span class="text-500">since last week</span>-->
        </div>
      </a>

    </div>
  </div>
</ng-template>