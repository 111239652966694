import { Component, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { IamSidebarComponent } from "./sidebar.component";

@Component({
    selector: 'iam-layout',
    templateUrl: './layout.component.html'
})
export class IamLayoutComponent /*extends AppLayoutComponent*/ {

  @ViewChild(IamSidebarComponent) appSidebar!: IamSidebarComponent;

  constructor(layoutService: LayoutService) {
    layoutService.state.sidebarMenuActive = true;

    layoutService.config.secondLogoText = 'Identity & Access Management';
    layoutService.config.secondLogoIcon = 'pi-id-card';
    layoutService.config.secondLogoUrl = '/iam';

    layoutService.config.dashboardBackground = false;
  }

}
