import { Component } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
    selector: 'gms-layout-lite',
    templateUrl: './layout-lite.component.html'
})
export class GmsLayoutLiteComponent /*extends AppLayoutComponent*/ {
  constructor(layoutService: LayoutService) {
    //super(layoutService);
    layoutService.state.sidebarMenuIconVisible = false;
    layoutService.config.secondLogoText = 'Governance Management';
    layoutService.config.secondLogoIcon = 'pi-shield';
  }
}
