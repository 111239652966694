import { v4 as uuidv4 } from 'uuid';

export class TenantDto {
  tenantId: string = uuidv4(); // This will be a GUID
  tenantName: string = '';

  constructor(partial?: Partial<TenantDto>) {
    Object.assign(this, partial);
  }
}

export enum RequestPermissionsType {
  Forbidden = 'Forbidden', // Use a descriptive string
  AnyUser = 'AnyUser',
  OwnUserOnly = 'OwnUserOnly',
  UserRoleInProject = 'UserRoleInProject',
  UserRole = 'UserRole',
  UserRoleInTenant = 'UserRoleInTenant',
}

export interface PermissionDictionary {
  [key: string]: RequestPermissionsType;
}

export class RequestPermissions {
  type: RequestPermissionsType = RequestPermissionsType.Forbidden;
  allowedRoles: string[] = [];

  constructor(partial?: Partial<RequestPermissions>) {
    Object.assign(this, partial);
  }
}


