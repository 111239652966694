import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IamStore } from '../../../services/iam.store';
import { iamEnvironment } from '../../../environments/environment';
import { PortalStore } from '../../../../portal/services/portal.store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  gpsLinkUrl: string = '';
  constructor(
    router: Router,
    portalStore: PortalStore,
  ) {
    this.gpsLinkUrl = iamEnvironment.gps[portalStore.current.tenant ?? 'VWGITC']??'';
  }
}
