// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { TmsEnvironmentConfig } from "./environment-config";

export const tmsEnvironment: TmsEnvironmentConfig = {
  api: { //change later to TMS API
    'CARIAD': {
      url: '#{cariadTmsApiUrl}#',
      clientId: '#{cariadTmsClientId}#',
      authority: '#{cariadTmsAuthority}#',
      scopes: ['#{cariadTmsAuthScope}#']
    },
    'VWGPROD': {
      url: '#{vwgprodTmsApiUrl}#',
      clientId: '#{vwgprodTmsClientId}#',
      authority: '#{vwgprodTmsAuthority}#',
      scopes: ['#{vwgprodTmsAuthScope}#']
    },
    'VWGST': undefined,
    'PowerCo': {
      url: '#{powercoTmsApiUrl}#',
      clientId: '#{powercoTmsClientId}#',
      authority: '#{powercoTmsAuthority}#',
      scopes: ['#{powercoTmsAuthScope}#']
    },
    'VWGITC': {
      url: '#{gitcTmsApiUrl}#',
      clientId: '#{gitcTmsClientId}#',
      authority: '#{gitcTmsAuthority}#',
      scopes: ['#{gitcTmsAuthScope}#']
    }
  }
};
