import { Component, OnInit } from '@angular/core';
import { TmsCommunicationService } from '../../../services/tms-communication.service';

@Component({
  selector: 'tms-logout',
  templateUrl: './logout.component.html',
})
export class TmsLogoutComponent implements OnInit {

  constructor(
      private communication: TmsCommunicationService,
    //private route: ActivatedRoute,
    //private portalCommunication: PortalCommunicationService
  ) {

    //const redirectTo = this.route.snapshot.queryParams['redirect'] ?? '/';

  }

  async ngOnInit() {
    //this.gpsAuth.logout()
    this.communication.userLogout$.next();
    }

}
