import { Inject, Injectable } from "@angular/core";
import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { portalEnvironment } from "../environments/portal-environment";
import { ApplicationType } from "../../shared/models/application-type";
import { ConfigServiceInterface } from "../../shared/abstract/config-service.interface";
import { APPLICATION_TYPE } from "../../../main";

export enum PortalMsalType {
  O365 = 'O365',
  Member = 'Member'
} 

@Injectable({providedIn: 'root'})
export class PortalConfigService implements ConfigServiceInterface{

  constructor(@Inject(APPLICATION_TYPE) private _msalType: PortalMsalType) {
  }

  private isMsalO365: boolean = this._msalType === PortalMsalType.O365;

  get applicationType(): ApplicationType { return ApplicationType.Portal; }
  
  get apiUrl(): string { return portalEnvironment.apiUrl; }
  get getPortalAppClientId(): string { return portalEnvironment.authMember.clientId; }

  getMsalScopes(): string[] {
    return this.isMsalO365
      ? portalEnvironment.authO365.scopes
      : portalEnvironment.authMember.scopes;
  }

  getMsalConfig(): Configuration {
    return this.isMsalO365
      ? {
        auth: {
          clientId: portalEnvironment.authO365.clientId,
          authority: portalEnvironment.authO365.authority,
          redirectUri: window.location.origin// + '/auth',
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
          allowNativeBroker: false, // Disables WAM Broker
          loggerOptions: {
            logLevel: LogLevel.Warning,
            loggerCallback: (logLevel: LogLevel, message: string) => console.log(message),
          }
        }
      }
      : {
        auth: {
          clientId: portalEnvironment.authMember.clientId,
          authority: portalEnvironment.authMember.authority,
          redirectUri: window.location.origin// + '/auth',
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
          allowNativeBroker: false, // Disables WAM Broker
          allowRedirectInIframe: true,
          loggerOptions: {
            logLevel: LogLevel.Warning,
            loggerCallback: (logLevel: LogLevel, message: string) => console.log(message),
          }
        }
      };
  }
}
