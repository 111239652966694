import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from './features/home/components/home.component';
import { TmsLayoutComponent } from "./features/layout/components/layout.component";
import { TmsLayoutLiteComponent } from "./features/layout/components/layout-lite.component";
import { TmsGuard } from "./services/tms-auth.service";
import { TmsLoginComponent } from "./features/home/components/login.component";
import { TmsLogoutComponent } from "./features/home/components/logout.component";

const routes: Routes = [
  {
    path: '', component: HomeComponent, pathMatch: 'full'
  },
  {
    path: 'tms', component: TmsLayoutComponent,
    //canActivate: [TmsGuard],
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
    ]
  },
  {
    path: 'tms/login', component: TmsLayoutLiteComponent,
    children: [
      { path: '', component: TmsLoginComponent },
    ]
  },
  {
    path: 'tms/logout', component: TmsLayoutLiteComponent,
    children: [
      { path: '', component: TmsLogoutComponent },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class TmsRoutingModule {}
