import { Component, OnInit } from '@angular/core';
import { GmsCommunicationService } from '../../../services/gms-communication.service';

@Component({
  selector: 'gms-logout',
  templateUrl: './logout.component.html',
})
export class GmsLogoutComponent implements OnInit {

  constructor(
    private communication: GmsCommunicationService,
  ) {
  }

  async ngOnInit() {
    this.communication.userLogout$.next();
  }

}
