
import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RedirectCommand, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, BrowserAuthError, createStandardPublicClientApplication, EventError, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { BehaviorSubject, Observable, filter, lastValueFrom, switchMap, take } from 'rxjs';
import { PortalCommunicationService } from '../../portal/services/portal-communication.service';
import { PortalStore } from '../../portal/services/portal.store';
import { TenantKey, getTenantById } from '../../shared/models/tenant';
import { TmsCommunicationService } from './tms-communication.service';
import { TmsConfigService } from './tms-configuration.service';
import { TmsStore } from './tms.store';
import { UserService } from './api/user.service';

@Injectable({ providedIn: 'root' })
export class TmsAuthService implements OnDestroy{

  public service!: MsalService;
  public configuration: TmsConfigService = new TmsConfigService();

  public inited$ = new BehaviorSubject<boolean>(false);

  private broadcast!: MsalBroadcastService;
  
  constructor(
    public store: TmsStore,
    public communication: TmsCommunicationService,
    private router: Router,
    private location: Location,
    private portalStore: PortalStore,
    private portalCommunication: PortalCommunicationService,
    private userService: UserService
  ) {
    //this.layout.startLoading();
    const tenant = this.portalStore.current.tenant;
    if (tenant) {
      try {
        this.store.setTenant(this.portalStore.current.tenant);
        console.log("TmsAuthService.constructor: set tenant from portal store", this.store.current.tenant);
        this.initForTenant(this.store.current.tenant!);
      } catch (error) {
        console.log("TmsAuthService.constructor: error setting tenant from portal store", error);
        this.login();
      }
    }

    this.communication.userLogin$.subscribe(() => {
      this.store.cleanUser();
      console.log('TmsAuthService: tmsCommunication.onUserLogin: logging in TMS');
      this.login();
    })

    this.communication.tenantChanged$.subscribe((tenantId) => {
      this.store.cleanUser();
      const tenant = getTenantById(tenantId);
      this.store.setTenant(tenant);
      console.log('TmsAuthService: tmsCommunication.switchTenant: re-login into TMS');
      this.login();
    })

    //logout also when portal user logs out
    this.communication.userLogout$.subscribe(() => {
      console.log('TmsAuthService: tmsCommunication.onUserLogout: logging out TMS');
      this.logout();
    })

    this.communication.userLoginCompleted$
      .pipe(filter((f) => f != null), take(1))
      .subscribe(() => {//u
        //this.layout.stopLoading();
        //this.userService.setBaseUrl(this.store.apiUrl);
        //this.userService.userMeGetPermissions()
        //  .subscribe((prm) => this.store.setPermissions(prm));
        //this.userService.userMeGet()
        //  .subscribe((user) => {
        //    this.store.setTmsUser(user);
        //  });
      });
  }

  findTokenPageUrl(redirect?:string): string { return `/tms/login?redirect=${redirect ?? window.location.pathname}`; }

  async initForTenant(tenant: TenantKey) {

    console.log('TmsAuthService: initForTenant', tenant);

    this.configuration.setTenant(tenant);
    this.portalStore.setTenant(tenant);
    this.store.setTenant(tenant);

    this.inited$.next(false);

    const pca = new PublicClientApplication(this.configuration.getMsalConfig());
    await pca.initialize();

    this.service = new MsalService(pca, this.location);

    this.broadcast = new MsalBroadcastService(this.service.instance, this.service);

    this.initBroadcast();

    //this.service.handleRedirectObservable()
    //  .subscribe({
    //    next: (result) => {
    //      console.log('TmsAuthService: handleRedirectObservable completed', result);
    //    },
    //    error: (error) => {
    //      console.error('TmsAuthService: handleRedirectObservable failed', error);
    //      this.loginFailedRedirect();
    //    }
    //  });


    this.service.handleRedirectObservable()
      .subscribe({
        next: (result) => {
          console.log('TmsAuthService: handleRedirectObservable completed', result);
        },
        error: (error) => {
          console.error('TmsAuthService: handleRedirectObservable failed', error);
          this.login();
        }
      });

    this.inited$.next(true);

    //await lastValueFrom(this.service.handleRedirectObservable());

    //try {
    //  await lastValueFrom(this.service.handleRedirectObservable());
    //} catch (error) {
    //  console.error('TmsAuthService: handleRedirectObservable failed', error);
    //  this.loginFailedRedirect();
    //}

      //await lastValueFrom(this.service.handleRedirectObservable()
    //  .pipe(catchError((e, d) => {
    //    console.error('[handleRedirectObservable]:', e);
    //    return d; // Return the caught observable to satisfy the type requirement
    //  })));

    console.log('TmsAuthService: initForTenant completed');
  }

  getAuthToken(): Observable<AuthenticationResult> {
    return this.service.acquireTokenSilent({
      //account: this.service.instance.getActiveAccount() ?? this.store.current.user,
      scopes: this.configuration.getMsalScopes()
    });
  }

  ngOnDestroy(): void {
    // Clean up the storage event listener when the service is destroyed
    //window.removeEventListener('storage', this.storageListener);
  }

  private async login(): Promise<void> {
    const activeAccount = this.portalStore.current.user;
    if (activeAccount) {
      await this.loginWithPortalAccount(activeAccount);
    } else {
      this.portalCommunication.userLogin$.next(this.store.applicationType);
      this.portalCommunication.userLoginCompleted$
        .pipe(filter(f => f != null), take(1))
        .subscribe(async (r) => {
          if (r as AccountInfo) {
            await this.loginWithPortalAccount(this.portalStore.current.user!);
          }
        });
    }
  }

  private loginWithPortalAccount(account: AccountInfo): Promise<void|AuthenticationResult> {
    return this.service.instance.ssoSilent({
      account: account,
      scopes: this.configuration.getMsalScopes()
    }).then(response => {
      // Successfully acquired token silently for App2
      console.log('TmsAuthService: ssoSilent completed');
      return response;
    }, error => {
      console.error("TmsAuthService: ssoSilent failed", error);
      // Handle failure, possibly by falling back to popup or redirect
      //this.portalCommunication.userLogin$.next(ApplicationType.Tms);
      //redirect to 
      this.router.navigateByUrl(this.router.parseUrl(this.findTokenPageUrl(this.router.url)), { skipLocationChange: true })
    });
  }

  private logout(): Observable<void> {
    this.store.cleanUser();
    return this.service.logoutRedirect({
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        return false;
      }
    });
  }

  private initBroadcast() {
    //const events: EventType[] = [EventType.INITIALIZE_END, EventType.LOGIN_SUCCESS, EventType.LOGIN_FAILURE, EventType.LOGOUT_END];
    this.broadcast.msalSubject$
      .pipe(
        //filter((msg: EventMessage) => events.includes(msg.eventType)),
      )
      .subscribe(async (msg: EventMessage) => {
        console.log(msg.eventType);

        switch (msg.eventType) {
          //case EventType.INITIALIZE_END:
          //  this.inited$.next(true);
          //  console.log('TmsAuthService.broadcast: TmsAuth inited');
          //  break;
          case EventType.LOGIN_SUCCESS:
            console.log('TmsAuthService.broadcast: Login success', msg.payload);
            this.setActiveAccount((msg.payload as AuthenticationResult).account);
            break;
          case EventType.LOGIN_FAILURE:
            console.error('TmsAuthService.broadcast: Login failed', msg);
            if (this.location.path().indexOf('/login') == -1) {
              this.loginFailedRedirect(msg.error);
            }
            break;
          case EventType.ACQUIRE_TOKEN_SUCCESS:
            //console.log('TmsAuthService.broadcast: Acquire token success', msg.payload);
            this.setActiveAccount((msg.payload as AuthenticationResult).account);
            break;
          case EventType.ACQUIRE_TOKEN_FAILURE:
            console.log('TmsAuthService.broadcast: Acquire token failed', msg.error);
            //handle error
            var authError = msg.error as BrowserAuthError;
            if (authError && authError.errorCode == 'login_required') {
              //login request
              this.communication.userLogin$.next(this.store.applicationType);
            }
            break;

          case EventType.LOGOUT_END:
            console.log('TmsAuthService.broadcast: Logout completed');
            this.communication.userLogoutCompleted$.next();
        }
      });
  }

  private setActiveAccount(activeAccount: AccountInfo) {
    let currentActiveAccountId = this.service.instance.getActiveAccount()?.idToken;

    if (currentActiveAccountId && currentActiveAccountId == activeAccount.idToken) {
      if (this.store.current.user?.idToken != currentActiveAccountId) {
        this.store.setUser(activeAccount);
        this.portalStore.setUser(activeAccount);
        console.log("TmsAuthService.broadcast: user set active account")
      }
      return;
    }

    this.service.instance.setActiveAccount(activeAccount);

    this.store.setUser(activeAccount);
    this.portalStore.setUser(activeAccount);
    let tenant = getTenantById(activeAccount?.tenantId ?? '');
    if (tenant && !this.store.current.tenant) {
      this.store.setTenant(tenant);
      //if (!this.portalStore.current.tenant) {
        this.portalStore.setTenant(tenant);
      //}
    }

    this.communication.userLoginCompleted$.next(activeAccount);

    console.log("TmsAuthService.broadcast: user set active account")
  }

  private loginFailedRedirect(error?: EventError) {
    console.log('TmsAuthService: redirect to login failed page', error);
    if (error) {
      this.communication.userLoginCompleted$.next(error);
    }
    this.router.navigate(['/error/login-failed']);
  }
}

export const TmsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {

  console.log('TmsGuard: start can activate');
  const router = inject(Router);
  const store = inject(TmsStore);
  try {
    const auth = inject(TmsAuthService);
    if (!store.current.tenant || !store.current.user || !auth.service.instance.getActiveAccount()) {
      console.log('TmsGuard: redirect to find token');
      return new RedirectCommand(router.parseUrl(auth.findTokenPageUrl(state.url)), { skipLocationChange: true });
    }
    return new Promise((resolve, reject) => {
      auth.inited$
        .pipe(filter(f => f), take(1))
        .subscribe((inited) => {
          if (inited) {
            console.log('TmsGuard: auth inited');
            if (!store.current.user) {
              console.log('TmsGuard: request login');
              auth.communication.userLogin$.next(store.applicationType);
              auth.communication.userLoginCompleted$
                .pipe(filter(f => f != null), take(1))
                .subscribe(() => {
                  console.log('TmsGuard: user login completed');
                  resolve(true);
                });
            }
            console.log('TmsGuard: resolved');
            resolve(inited);
          }
        });
    })
  } catch (error) {
    store.setTenant(undefined);
    return new RedirectCommand(router.parseUrl('/tms/login'), { skipLocationChange: true });
  }
};


@Injectable()
export class TmsInterceptor implements HttpInterceptor {

  constructor(private authService: TmsAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //TODO: add error handling
   // const isProtectedResource = req.url.startsWith(this.authService.configuration.apiUrl) || false;
    const isProtectedResource = req.headers.get('X-App') == this.authService.store.applicationType || false;
    if (isProtectedResource) {
      return this.authService.getAuthToken().pipe(
        switchMap((result: AuthenticationResult) => {

          const headers = req.headers.set(
            "Authorization",
            `Bearer ${result.accessToken}`
          );

          const requestClone = req.clone({ headers });

          return next.handle(requestClone);
        })
      );
    } else {
      // If not protected, forward the original request
      return next.handle(req);
    }
  }
}
