import { Component } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { AppLayoutComponent } from './app.layout.component';
import { portalEnvironment } from '../../../environments/portal-environment';

@Component({
    selector: 'portal-layout',
    templateUrl: './portal.layout.component.html'
})
export class PortalLayoutComponent extends AppLayoutComponent {

  version: string = portalEnvironment.version;
  constructor(layoutService: LayoutService) {
    super(layoutService);

    layoutService.config.secondLogoText = null;
    layoutService.config.secondLogoIcon = '';

  }
}
