
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../../shared/services/_base-api.service';
import { TmsStore } from '../tms.store';
import { PermissionDictionary, RequestPermissionsType } from './configuration.model';
import { UserMeDto, UserRoles } from './user.model';
import { TmsConfigService } from '../tms-configuration.service';

export const TmsUserDetailsApiUrl = {
  UserGetMe: "/api/users/me",
  UserGetMePermissions: "/api/users/me/permissions"
}

@Injectable({providedIn: 'root'})
export class UserService extends BaseApiService{

  constructor(private store: TmsStore) {
    super(new TmsConfigService(), store);
  }

  //#region Users

  userMeGet(): Observable<UserMeDto> {
    return super.get(TmsUserDetailsApiUrl.UserGetMe);
  }

  userMeGetPermissions(): Observable<PermissionDictionary> {
    return super.get<PermissionDictionary>(TmsUserDetailsApiUrl.UserGetMePermissions);
  }

  //#endregion

  hasPermission(permissions: string, roles: string[] = []): boolean {
    //if user has Admin or Ops role, return true
    if (roles.includes(UserRoles.Administrator) || roles.includes(UserRoles.Operations)) {
      return true;
    }

    const permissionsArray = (permissions?.split(',') ?? [])
      .map(p => this.store.current?.permissions?.[p] ?? RequestPermissionsType.Operations);

    if (!permissionsArray.length) {
      return true;
    }

    const allowed = [];
    //check endpoint permissions in array
    for (let permission of permissionsArray) {
      switch (permission) {
        case RequestPermissionsType.Forbidden:
          allowed.push(false);
          break;
        case RequestPermissionsType.AnyUser:
          allowed.push(true);
          break;
        case RequestPermissionsType.Operations:
          allowed.push(roles.includes(UserRoles.Operations) ?? roles.includes(UserRoles.Administrator) ?? false);
          break;
        case RequestPermissionsType.Administrator:
          allowed.push(roles.includes(UserRoles.Administrator) ?? false);
          break;
        default:
          allowed.push(false);
      }
    }
    //if any is true, return true
    return allowed.some(p => p);
  }
}
