
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseApiService } from '../../../shared/services/_base-api.service';
import { GmsStore } from '../gms.store';
import { RequestPermissionsType } from './configuration.model';
import { UserMeDto } from './user.model';
import { GmsConfigService } from '../gms-configuration.service';

export const GmsUserDetailsApiUrl = {
  UserMe: "/users/me",
}

@Injectable({providedIn: 'root'})
export class UserService extends BaseApiService{

  constructor(config: GmsConfigService, private store: GmsStore) {
    super(config, store);
  }

  //#region Users

  userMeGet(): Observable<UserMeDto> {
    return super.get<UserMeDto>(GmsUserDetailsApiUrl.UserMe)
      .pipe(map(u => new UserMeDto(u)));
  }

  //#endregion

  hasPermission(permissions: string, roles: string[] = []): boolean {
    //if user has Admin or Ops role, return true
    //if (roles.includes(UserRoles.Administrator)) { //|| roles.includes(UserRoles.Operations)
    //  return true;
    //}

    const permissionsArray = (permissions?.split(',') ?? [])
      .map(p => this.store.current?.permissions?.[p] ?? p);

    if (!permissionsArray.length) {
      return true;
    }

    const allowed = [];
    //check endpoint permissions in array
    for (let permission of permissionsArray) {
      switch (permission.split(':')[0]) {
        case RequestPermissionsType.Forbidden:
          allowed.push(false);
          break;
        case RequestPermissionsType.AnyUser:
          allowed.push(true);
          break;
        case RequestPermissionsType.UserRole:
          allowed.push(roles.includes(permission.split(':')[1]) ?? false);
          //allowed.push(roles.includes(UserRoles.Operations) ?? roles.includes(UserRoles.Administrator) ?? false);
          break;
        default:
          allowed.push(true);
      }
    }
    //if any is true, return true
    return allowed.some(p => p);
  }
}
