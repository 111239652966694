import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { CommonModule } from '@angular/common';
import { APPLICATION_TYPE } from '../../main';
import { ApplicationType } from '../shared/models/application-type';
import { HomeModule } from './features/home/home.module';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalAuthService, PortalInterceptor } from './services/portal-auth.service';
import { PortalCommunicationService } from './services/portal-communication.service';
import { SelectTenantComponent } from './features/layout/components/select-tenant-component/select.tenant.component';


@NgModule({
  declarations: [
    SelectTenantComponent
  ],
  imports: [
    PortalRoutingModule,
    CommonModule,
    ButtonModule,
    MenubarModule,

    HomeModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    PortalAuthService,
    PortalCommunicationService,

    { provide: APPLICATION_TYPE, useValue: ApplicationType.Portal },

    //{ provide: APPLICATION_TENANT, useFactory: (store: PortalStore) => store.current.tenant, deps: [PortalStore] },

    { provide: HTTP_INTERCEPTORS, useClass: PortalInterceptor, deps: [PortalAuthService], multi: true },
    //{ provide: HTTP_INTERCEPTORS, useFactory(auth: PortalAuthService) { return auth.interceptor }, deps: [PortalAuthService], multi: true },
  ],
  bootstrap: [] //PortalAppComponent, 
})
export class PortalModule {}
