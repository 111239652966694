import { Component, OnInit } from '@angular/core';
import { IamStore } from '../../../services/iam.store';
import { iamEnvironment } from '../../../environments/environment';
import { PortalStore } from '../../../../portal/services/portal.store';

@Component({
    selector: 'iam-menu',
    templateUrl: './menu.component.html'
})
export class IamMenuComponent implements OnInit {

  items: any[] = [];

  allItems = [
    {
      label: 'Grant Permission Service',
      icon: 'pi pi-fw pi-shield',
      url: iamEnvironment.gps[this.portalStore.current.tenant ?? 'VWGITC']  
    },
    //{
    //    label: 'Key Rotation Service',
    //    icon: 'pi pi-fw pi-key',
    //},
    //{
    //    label: 'Application Developer Role',
    //    icon: 'pi pi-fw pi-user',
    //},
    {
      label: 'Privilege Role Management',
      icon: 'pi pi-fw pi-id-card',
      routerLink: '/iam/privileged-group/assign',
    }
  ];

  constructor(
    private store:IamStore,
    private portalStore: PortalStore
  ) { }

  ngOnInit() {
    this.items = this.allItems;
  }
}
