<app-layout>
  <div class="flex flex-column align-items-stretch flex-grow-1">
    <portal-progress-layout></portal-progress-layout>
    <router-outlet></router-outlet>
  </div>
  <div class="layout-footer surface-ground ">
    <div class="grid grid-nogutter h-4rem">
      <div class="col-1"></div>
      <div class="col-10 text-center">
        <img src="images/brand.svg" style="height:52px;"/>
      </div>
      <div class="col-1 flex align-items-center justify-content-center">{{version}}</div>
    </div>
  </div>
</app-layout>
