<div class="flex align-items-center justify-content-center mt-5 min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <div style="padding: 0.3rem; background: linear-gradient(180deg, rgba(1, 70, 103, 0.6) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center">
                <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">Select a tenant:</h1>
                <div class="text-600 mb-5">Please select the tenant you want to use from the list of available tenants for your user</div>

                <div *ngIf="tenantList.length > 0; else noTenants">
                        <button (click)="selectTenant(tenant.id)" *ngFor="let tenant of tenantList" pButton severity="info" class="text-900 font-medium text-xl m-2">{{ tenant.name }}</button>
                </div>
                
                <ng-template #noTenants>
                    <p>No tenants available for your account.</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>
