import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, takeUntil, tap } from 'rxjs';
import { NpsStore } from '../../../services/nps.store';
import { UserMeDto } from '../../shared/api/user-details.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  userArrived$: BehaviorSubject<boolean>;
  npsUser$: Observable<UserMeDto | undefined>;

  constructor(
    router: Router,
    private store: NpsStore
  ) {
    this.userArrived$ = new BehaviorSubject<boolean>(false);

    this.npsUser$ = this.store.npsUser$
      .pipe(
        tap(user => this.userArrived$.next(!!user) )
      );

    // something needs to subscribe to it to ask for user before we'll redraw the page and directives will subscribe to it
    this.npsUser$.pipe(takeUntil(this.userArrived$.pipe(filter(stop => stop === true)))).subscribe()
  }
}
