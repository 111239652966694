import { Component, ElementRef } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
    selector: 'gms-sidebar',
  template: '<gms-menu></gms-menu>'
})
export class GmsSidebarComponent {
    constructor(public layoutService: LayoutService, public el: ElementRef) { }
}

