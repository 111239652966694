import { Location } from '@angular/common';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventError, EventMessage, EventType, InteractionRequiredAuthError, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { BehaviorSubject, Observable, filter, lastValueFrom, map, switchMap, take, takeUntil } from 'rxjs';
import { getTenantById } from '../../shared/models/tenant';
import { PortalCommunicationService } from './portal-communication.service';
import { PortalConfigService, PortalMsalType } from "../services/portal-configuration.service";
import { PortalStore } from '../services/portal.store';
import { ApplicationType } from '../../shared/models/application-type';
import { LayoutService } from '../../shared/services/layout.service';
import { TenantResponse } from '../../shared/models/tenant-response';

@Injectable({providedIn: 'root'})
export class PortalAuthService {

  public service!: MsalService;
  public inited$ = new BehaviorSubject<boolean>(false);
  public configuration!: PortalConfigService;
  private armApiUrl = 'https://management.azure.com';
  availableTenants: any[] = [];

  private broadcast!: MsalBroadcastService;
  private location: Location;

  constructor(
    public store: PortalStore,
    public communication: PortalCommunicationService,
    private router: Router,
    private layout: LayoutService,
    private http: HttpClient,
    location: Location
  ) {
    this.location = location;
    this.layout.startLoading();
    //decide which type of login to use
    //if not standard login page, use value from storage or default
    if (window.location.pathname.indexOf('member-login') > -1) {
      this.init(PortalMsalType.Member, location);
    } else {
      this.init(store.current.msalType ?? PortalMsalType.O365, location);
    }

    this.communication.userLogin$.subscribe(() => {
      this.login();
    })

    this.communication.switchTenant$.subscribe(async (tenantId) => {
      const tenant = getTenantById(tenantId);
      this.store.setTenant(tenant);
    
        await this.performTenantLogin(tenantId);
        //this.communication.tenantSwitchInitForAllApps(tenantId);
    })

    this.communication.userLogout$.subscribe(() => {
      this.communication.logoutAllApps().then(() => {
        this.logout();
      });
    })

    this.communication.userLogoutCompleted$.subscribe(() => {
      //reload after logout
      window.location.reload();
    })

    this.communication.userLoginCompleted$
      .pipe(filter((f) => f != null), take(1))
      .subscribe(() => {
        this.layout.stopLoading();
      });

    //set if already logged in
    this.setActiveAccount();

    //watch for clear local storage event 
    window.addEventListener('storage', (d: StorageEvent) => this.storageListener(d));
  }

  getAuthToken(): Observable<AuthenticationResult> {
    if (!this.service.instance.getActiveAccount()) {
      this.login();
      return new Observable<AuthenticationResult>();
    }
    return this.service.acquireTokenSilent({
      scopes: this.configuration.getMsalScopes()
    });
  }

  storageListener(d: StorageEvent) {
    if (!d.key && !d.oldValue && !d.newValue) {
      this.communication.userLogout$.next();
    }
  }


  private init(msalType: PortalMsalType, location: Location) {
    this.store.setMsalType(msalType);
    switch (msalType) {
      case PortalMsalType.O365:
        this.initO365(location);
        break;
      case PortalMsalType.Member:
        this.initMember(location);
        break;
    }
  }

  private initO365(location: Location) {
    this.configuration = new PortalConfigService(PortalMsalType.O365);
    this.service = new MsalService(new PublicClientApplication(this.configuration.getMsalConfig()), location);
    this.broadcast = new MsalBroadcastService(this.service.instance, this.service);

    this.initBroadcast();

    console.log('PortalAuthService.init Type: ', PortalMsalType.O365);
  }

  private initMember(location: Location) {
    this.configuration = new PortalConfigService(PortalMsalType.Member);
    this.service = new MsalService(new PublicClientApplication(this.configuration.getMsalConfig()), location);
    this.broadcast = new MsalBroadcastService(this.service.instance, this.service);

    this.initBroadcast();

    console.log('PortalAuthService.init Type:', PortalMsalType.Member);
  }

  private async login(): Promise<void> {
    try {
        console.log("Starting login...");

        const originalUrl = this.router.url;
        localStorage.setItem('originalUrl', originalUrl); 

        const result = await lastValueFrom(
            this.service.loginPopup({
                scopes: this.configuration.getMsalScopes()
            })
        );

        console.log("PortalAuthService: Login successful", result);

        this.service.instance.setActiveAccount(result.account);
        this.store.setUser(result.account);

        if (this.store.current.msalType === PortalMsalType.O365) {
            console.log("Using O365 authentication, redirecting to select-tenant...");
            console.log("O365 login detected, checking tenants...");
              await this.getAvailableTenantsForUser();
              
              const existingTenants = this.store.current.availableTenants;
                console.log("Redirecting user to tenant selection page...");
                this.router.navigate(['/select-tenant']);
                return;
        }

        const redirectUrl = localStorage.getItem('originalUrl') || '/';
        localStorage.removeItem('originalUrl'); 
        this.router.navigate([redirectUrl]);

    } catch (error) {
        console.error("PortalAuthService: Login failed", error);
    }
}



  private logout(): Observable<void> {
    this.store.clean();
    const account = this.store.current.user;
    return this.service.logoutPopup({ account });
  }

  private initBroadcast() {
    const events: EventType[] = [
      EventType.INITIALIZE_END,
      EventType.LOGIN_FAILURE,
      EventType.LOGOUT_END,
      EventType.LOGIN_SUCCESS,
      EventType.HANDLE_REDIRECT_END,
    ];
  
    this.broadcast.msalSubject$
      .pipe(filter((msg: EventMessage) => events.includes(msg.eventType)))
      .subscribe(async (msg: EventMessage) => {
        console.log("MSAL Event Received:", msg.eventType);
  
        switch (msg.eventType) {
          case EventType.INITIALIZE_END:
            this.inited$.next(true);
            console.log("PortalAuthService.broadcast: PortalAuth initialized");
            break;
  
          case EventType.LOGIN_FAILURE:
            console.error("PortalAuthService.broadcast: Login failed", msg.error);
            this.store.setTenant(undefined);
            this.loginFailedRedirect(msg.error);
            break;

          case EventType.LOGOUT_END:
            console.log("PortalAuthService.broadcast: Logout completed");
            this.communication.userLogoutCompleted$.next();
            break;
        }
      });
  
    this.broadcast.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.setActiveAccount(); 
      });
  }
  

  async performTenantLogin(tenantId: string): Promise<void> {
    console.log("Attempting SSO silent login for selected tenant "+ tenantId);

    let msalConfig = this.configuration.getMsalConfig();
    msalConfig.auth.authority = `https://login.microsoftonline.com/${tenantId}`;
    msalConfig.auth.clientId = this.configuration.getPortalAppClientId;

    const newMsalInstance = new PublicClientApplication(msalConfig);

    await newMsalInstance.initialize(); 

    this.service = new MsalService(newMsalInstance, this.location);

    try {
        let activeAccount = this.service.instance.getAllAccounts()[0]; 

        if (!activeAccount) {
            console.error("No active account found after rebuilding MSAL. Cannot perform SSO login.");
            return;
        }

        this.service.instance.setActiveAccount(activeAccount);

        const result = await this.service.acquireTokenSilent({
            account: activeAccount,
            scopes: this.configuration.getMsalScopes()
        }).toPromise(); 

        console.log("SSO silent login successful for tenant:", tenantId);
        console.log("token from silent auth: ", result);
        this.service.instance.setActiveAccount(result!.account);
        this.store.setUser(result!.account);

        const redirectUrl = localStorage.getItem('originalUrl') || '/';
        localStorage.removeItem('originalUrl');
        console.log("Redirecting user back to:", redirectUrl);
        this.router.navigate([redirectUrl]);

    } catch (error) {
        console.error("SSO silent login failed:", error);
        if (error instanceof InteractionRequiredAuthError) {
            console.warn("Silent login failed due to Conditional Access. Prompting user...");

            try {
                const popupResult = await this.service.loginPopup({
                    scopes: this.configuration.getMsalScopes()
                }).toPromise();

                console.log("Popup login successful:", popupResult);

                this.service.instance.setActiveAccount(popupResult!.account);
                this.store.setUser(popupResult!.account);

                const redirectUrl = localStorage.getItem('originalUrl') || '/';
                localStorage.removeItem('originalUrl');
                this.router.navigate([redirectUrl]);

            } catch (popupError) {
                console.error("Popup login failed:", popupError);
                this.router.navigate(['/login']);
            }
        } else {
            console.error("Unexpected login error:", error);
            this.router.navigate(['/login']);
        }
    }
    this.communication.tenantSwitchInitForAllApps(tenantId);
}


  
  private async getAvailableTenantsForUser(): Promise<void> {
    if (this.store.current.msalType !== PortalMsalType.O365) {
      return;
    }
    console.log("Fetching available tenants for O365...");
  
    try {
      const activeAccount = this.service.instance.getActiveAccount();
      if (!activeAccount) {
        console.warn("No active account found.");
        return;
      }
  
      const tokenResult = await lastValueFrom(
        this.service.acquireTokenSilent({
          scopes: ["https://management.azure.com/.default"],
          forceRefresh: true,
        })
      );
  
      const accessToken = tokenResult.accessToken;
  
      this.getUserTenants(accessToken).subscribe(response => {
        if (response.value.length > 0) {
          const tenantIds = response.value.map(tenant => tenant.tenantId);
          console.log(`Found ${tenantIds.length} tenants for user ${activeAccount.username}`);
  
          tenantIds.forEach(tenantId => {
            this.store.setAddAvailableTenant(tenantId);
          });
        } else {
          console.warn("No tenants found for the user.");
        }
      });
  
    } catch (error) {
      console.error("Failed to fetch available tenants:", error);
    }
  }

  private setActiveAccount() {
    let activeAccount = this.service.instance.getActiveAccount();

    if (!activeAccount && this.service.instance.getAllAccounts().length > 0) {
      activeAccount = this.service.instance.getAllAccounts()[0];
      this.service.instance.setActiveAccount(activeAccount);
    }
    if (activeAccount) {
      this.store.setUser(activeAccount);
      let tenant = getTenantById(activeAccount?.tenantId ?? '');
      if (tenant && !this.store.current.tenant) {
        this.store.setTenant(tenant);
      }

      this.communication.userLoginCompleted$.next(activeAccount);
    }
  }
  private getUserTenants(accessToken: string): Observable<TenantResponse> {
    return this.http.get<any>(`${this.armApiUrl}/tenants?api-version=2019-06-01`, { 
      headers: new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      })
    }).pipe(
      map((response: any) => new TenantResponse(response)) // Explicitly type response
    );
  }
  
  private loginFailedRedirect(error?: EventError) {
    console.log('PortalAuthService: redirect to login failed page', error);
    if (error) {
      this.communication.userLoginCompleted$.next(error);
    }
    this.router.navigate(['/error/login-failed']);
  }
}

export const PortalGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  //TODO: add error handling
  const auth = inject(PortalAuthService);
  return new Promise((resolve, reject) => {
    auth.inited$
      .pipe(filter(f => f), take(1))
      .subscribe((inited) => {
        if (inited) {
          if (!auth.store.current.user) {
            auth.communication.userLogin$.next(ApplicationType.Portal);
            auth.communication.userLoginCompleted$
              .pipe(filter(f => f != null), take(1))
              .subscribe((r) => {
                resolve(true);
              });
          }
          resolve(inited);
        }
      });
  })
};


@Injectable()
export class PortalInterceptor implements HttpInterceptor {

  constructor(private authService: PortalAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //TODO: add error handling
    const isProtectedResource = req.url.startsWith(this.authService.configuration.apiUrl) || false;
    if (isProtectedResource) {
      return this.authService.getAuthToken().pipe(
        switchMap((result: AuthenticationResult) => {
          const headers = req.headers.set(
            "Authorization",
            `Bearer ${result.accessToken}`
          );

          const requestClone = req.clone({ headers });
          return next.handle(requestClone);
        })
      );
    } else {
      // If not protected, forward the original request
      return next.handle(req);
    }
  }
}
