import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RedirectCommand, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, BrowserAuthError, EventError, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { BehaviorSubject, Observable, filter, lastValueFrom, switchMap, take } from 'rxjs';
import { PortalCommunicationService } from '../../portal/services/portal-communication.service';
import { PortalStore } from '../../portal/services/portal.store';
import { TenantKey, getTenantById } from '../../shared/models/tenant';
import { LayoutService } from '../../shared/services/layout.service';
import { ConfigurationService } from '../features/shared/api/configuration.service';
import { UserDetailsService } from '../features/shared/api/user-details.service';
import { NpsCommunicationService } from './nps-communication.service';
import { NpsConfigService } from './nps-configuration.service';
import { NpsStore } from './nps.store';

@Injectable({ providedIn: 'root' })
export class NpsAuthService implements OnDestroy{

  public service!: MsalService;
  //public configuration: NpsConfigService = new NpsConfigService();

  public inited$ = new BehaviorSubject<boolean>(false);

  private broadcast!: MsalBroadcastService;

  constructor(
    public store: NpsStore,
    public communication: NpsCommunicationService,
    public configuration: NpsConfigService, 
    private router: Router,
    private location: Location,
    private portalStore: PortalStore,
    private portalCommunication: PortalCommunicationService,
    private layout: LayoutService,
    private configurationService: ConfigurationService,
    private userService: UserDetailsService
  ) {
    //this.layout.startLoading();
    const tenant = this.portalStore.current.tenant;
    if (tenant) {
      try {
        //this.store.setTenant(this.portalStore.current.tenant);
        console.log("NpsAuthService.constructor: set tenant from portal store", this.portalStore.current.tenant);
        this.initForTenant(this.portalStore.current.tenant!);
      } catch (error) {
        console.log("NpsAuthService.constructor: error setting tenant from portal store", error);
        this.login();
      }
    }

    this.communication.userLogin$.subscribe(() => {
      this.store.cleanUser();
      console.log('NpsAuthService: npsCommunication.onUserLogin: logging in NPS');
      this.login();
    })

    this.communication.tenantChanged$.subscribe((tenantId) => {
      this.store.cleanUser();
      const tenant = getTenantById(tenantId);
      //this.store.setTenant(tenant);
      console.log('NpsAuthService: npsCommunication.switchTenant: re-login into NPS');
      this.login();
    })

    //logout also when portal user logs out
    this.communication.userLogout$.subscribe(() => {
      console.log('NpsAuthService: npsCommunication.onUserLogout: logging out NPS');
      this.logout();
    })

    this.communication.userLoginCompleted$
      .pipe(filter((f) => f != null), take(1))
      .subscribe((u) => {
        //this.layout.stopLoading();

        // this.configurationService.setBaseUrl(this.configuration.apiUrl);
        // this.userService.setBaseUrl(this.configuration.apiUrl);

        this.configurationService.permissions()
          .subscribe((prm) => this.store.setPermissions(prm));
        this.userService.userMeGet()
          .subscribe((user) => {
            this.store.setNpsUser(user);
          });
      });
  }

  findTokenPageUrl(redirect?:string): string { return `/nps/login?redirect=${redirect ?? window.location.pathname}`; }

  async initForTenant(tenant: TenantKey) {

    console.log('NpsAuthService: initForTenant', tenant);

    this.configuration.setTenant(tenant);
    this.portalStore.setTenant(tenant);
    //this.store.setTenant(tenant);

    this.service = new MsalService(new PublicClientApplication(this.configuration.getMsalConfig()), this.location);

    this.broadcast = new MsalBroadcastService(this.service.instance, this.service);

    this.initBroadcast();

    await lastValueFrom(this.service.handleRedirectObservable());
    //this.service.handleRedirectObservable().subscribe();

    console.log('NpsAuthService: initForTenant completed');
  }

  getAuthToken(): Observable<AuthenticationResult> {
    //if (!this.service.instance.getActiveAccount()) {
    //  this.router.navigate([this.findTokenPageUrl(this.router.url)], { skipLocationChange: true });
    //  return new Observable<AuthenticationResult>();
    //}
    return this.service.acquireTokenSilent({
      scopes: this.configuration.getMsalScopes()
    });
  }

  ngOnDestroy(): void {
    // Clean up the storage event listener when the service is destroyed
    //window.removeEventListener('storage', this.storageListener);
  }

  private async login(): Promise<void> {
    const activeAccount = this.portalStore.current.user;
    if (activeAccount) {
      await this.loginWithPortalAccount(activeAccount);
    } else {
      this.portalCommunication.userLogin$.next(this.store.applicationType);
      this.portalCommunication.userLoginCompleted$
        .pipe(filter(f => f != null), take(1))
        .subscribe(async (r) => {
          if (r as AccountInfo) {
            await this.loginWithPortalAccount(this.portalStore.current.user!);
          }
        });
    }
  }

  private loginWithPortalAccount(account: AccountInfo): Promise<void|AuthenticationResult> {
    return this.service.instance.ssoSilent({
      account: account,
      scopes: this.configuration.getMsalScopes()
    }).then(response => {
      // Successfully acquired token silently for App2
      console.log('NpsAuthService: ssoSilent completed');
      return response;
    }, error => {
      console.error("NpsAuthService: ssoSilent failed", error);
      // Handle failure, possibly by falling back to popup or redirect
      //this.portalCommunication.userLogin$.next(ApplicationType.Nps);
      //redirect to
      this.router.navigateByUrl(this.router.parseUrl(this.findTokenPageUrl(this.router.url)), { skipLocationChange: true })
    });
  }

  private logout(): Observable<void> {
    this.store.cleanUser();
    return this.service.logoutRedirect({
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        return false;
      }
    });
  }

  private initBroadcast() {
    //const events: EventType[] = [EventType.INITIALIZE_END, EventType.LOGIN_SUCCESS, EventType.LOGIN_FAILURE, EventType.LOGOUT_END];
    this.broadcast.msalSubject$
      .pipe(
        //filter((msg: EventMessage) => events.includes(msg.eventType)),
      )
      .subscribe(async (msg: EventMessage) => {
        console.log(msg.eventType);

        switch (msg.eventType) {
          case EventType.INITIALIZE_END:
            this.inited$.next(true);
            console.log('NpsAuthService.broadcast: NpsAuth inited');
            break;
          case EventType.LOGIN_SUCCESS:
            console.log('NpsAuthService.broadcast: Login success', msg.payload);
            this.setActiveAccount((msg.payload as AuthenticationResult).account);
            break;
          case EventType.LOGIN_FAILURE:
            console.error('NpsAuthService.broadcast: Login failed', msg);
            if (this.location.path().indexOf('/login') == -1) {
              this.loginFailedRedirect(msg.error);
            }
            break;
          case EventType.ACQUIRE_TOKEN_SUCCESS:
            //console.log('NpsAuthService.broadcast: Acquire token success', msg.payload);
            this.setActiveAccount((msg.payload as AuthenticationResult).account);
            break;
          case EventType.ACQUIRE_TOKEN_FAILURE:
            console.log('NpsAuthService.broadcast: Acquire token failed', msg.error);
            //handle error
            var authError = msg.error as BrowserAuthError;
            if (authError && authError.errorCode == 'login_required') {
              //login request
              this.communication.userLogin$.next(this.store.applicationType);
            }
            break;

          case EventType.LOGOUT_END:
            console.log('NpsAuthService.broadcast: Logout completed');
            this.communication.userLogoutCompleted$.next();
        }
      });
  }

  private setActiveAccount(activeAccount: AccountInfo) {
    let currentActiveAccountId = this.service.instance.getActiveAccount()?.idToken;

    if (currentActiveAccountId && currentActiveAccountId == activeAccount.idToken) {
      if (this.store.current.user?.idToken != currentActiveAccountId) {
        this.store.setUser(activeAccount);
        this.portalStore.setUser(activeAccount);
        this.communication.userLoginCompleted$.next(activeAccount); //???
      }
      return;
    }

    this.service.instance.setActiveAccount(activeAccount);

    this.store.setUser(activeAccount);
    this.portalStore.setUser(activeAccount);
    let tenant = getTenantById(activeAccount?.tenantId ?? '');
    // if (tenant && !this.store.current.tenant) {
    //   this.store.setTenant(tenant);
    //   //if (!this.portalStore.current.tenant) {
    //     this.portalStore.setTenant(tenant);
    //   //}
    // }

    this.communication.userLoginCompleted$.next(activeAccount);

    console.log("NpsAuthService.broadcast: user set active account")
  }

  private loginFailedRedirect(error?: EventError) {
    console.log('NpsAuthService: redirect to login failed page', error);
    if (error) {
      this.communication.userLoginCompleted$.next(error);
    }
    this.router.navigate(['/error/login-failed']);
  }
}

export const NpsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {

  console.log('NpsGuard: start can activate');
  const router = inject(Router);
  const store = inject(NpsStore);
  const portalStore = inject(PortalStore);
  try {
    const auth = inject(NpsAuthService);
    if (!portalStore.current.tenant || !store.current.user) {
      console.log('NpsGuard: redirect to find token');
      return new RedirectCommand(router.parseUrl(auth.findTokenPageUrl(state.url)), { skipLocationChange: true });
    }
    return new Promise((resolve, reject) => {
      auth.inited$
        .pipe(filter(f => f), take(1))
        .subscribe((inited) => {
          if (inited) {
            console.log('NpsGuard: auth inited');
            if (!store.current.user) {
              console.log('NpsGuard: request login');
              auth.communication.userLogin$.next(store.applicationType);
              auth.communication.userLoginCompleted$
                .pipe(filter(f => f != null), take(1))
                .subscribe((r) => {
                  console.log('NpsGuard: user login completed');
                  resolve(true);
                });
            }
            console.log('NpsGuard: resolved');
            resolve(inited);
          }
        });
    })
  } catch (error) {
    //store.setTenant(undefined);
    return new RedirectCommand(router.parseUrl('/nps/login'), { skipLocationChange: true });
  }
};


@Injectable()
export class NpsInterceptor implements HttpInterceptor {

  constructor(private authService: NpsAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //TODO: add error handling
    //const isProtectedResource = req.url.startsWith(this.authService.configuration.apiUrl) || false;
    const isProtectedResource = req.headers.get('X-App') == this.authService.store.applicationType || false;
    if (isProtectedResource) {
      return this.authService.getAuthToken().pipe(
        switchMap((result: AuthenticationResult) => {

          const headers = req.headers.set(
            "Authorization",
            `Bearer ${result.accessToken}`
          );

          const requestClone = req.clone({ headers });

          return next.handle(requestClone);
        })
      );
    } else {
      // If not protected, forward the original request
      return next.handle(req);
    }
  }
}

