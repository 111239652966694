<h1 class="text-center mt-5">IAM: Identity &amp; Access Management</h1>

<div class="px-4 py-5 md:px-6 lg:px-8">
  <div class="grid justify-content-center">

    <a class="col-12 md:col-6 lg:col-4" href="{{gpsLinkUrl}}" target="_blank">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">GPS</span>
            <div class="text-900 font-medium text-xl">Grant Permission Service</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-shield text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>

    <!--<a class="col-12 md:col-6 lg:col-4" routerLink="./">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">KRS</span>
            <div class="text-900 font-medium text-xl">Key Rotation Service</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-key text-red-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>-->


    <!--<a class="col-12 md:col-6 lg:col-4" routerLink="./">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">AD Role</span>
            <div class="text-900 font-medium text-xl">Application Developer Role</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-users text-gray-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>-->

    <a class="col-12 md:col-6 lg:col-4" routerLink="./privileged-group/assign">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Privilege Role</span>
            <div class="text-900 font-medium text-xl">Privilege Role Management</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-id-card text-green-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>

  </div>
</div>
