<app-layout [containerClass]="{'layout-static-inactive':true}">
  <div class="flex flex-row align-items-stretch flex-grow-1">
    <div class="layout-sidebar ">
      <iam-sidebar></iam-sidebar>
    </div>
    <div class="layout-main-container flex-grow-1 flex flex-column align-items-stretch">
      <div class="layout-main px-3 py-1 flex flex-column flex-grow-1 align-items-stretch">
        <router-outlet></router-outlet>
      </div>
      <div class="layout-footer surface-ground flex justify-content-center h-4rem">
        <img src="images/brand.svg" />
      </div>
    </div>
  </div>
</app-layout>

