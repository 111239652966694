import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { HomeModule } from './features/home/home.module';
import { GmsLayoutModule } from './features/layout/gms.layout.module';
import { GmsRoutingModule } from './gms-routing.module';
import { LayoutService } from '../shared/services/layout.service';
import { GmsAuthService, GmsInterceptor } from './services/gms-auth.service';
import { GmsCommunicationService } from './services/gms-communication.service';
import { GovernanceVisualizerModule } from './features/governance-visualizer/governance-visualizer.module';
import { WaiverModule } from './features/waivers/waiver.module';


@NgModule({
  imports: [
    ButtonModule,
    MenubarModule,
    ConfirmDialogModule,
    ToastModule,

    GmsLayoutModule,
    HomeModule,

    GovernanceVisualizerModule,
    WaiverModule,

    GmsRoutingModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),

    GmsAuthService,
    GmsCommunicationService,

    { provide: HTTP_INTERCEPTORS, useClass: GmsInterceptor, deps: [GmsAuthService, LayoutService], multi: true },
  ]
})
export class GmsModule {}
