import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { filter } from 'rxjs';
import { GmsStore } from '../services/gms.store';
import { UserService } from '../services/api/user.service';

@Directive({
  selector: '[gmsPermission]'
})
export class GmsPermissionDirective implements OnInit, OnDestroy {
  @Input() gmsPermission: string = ''; 
  private visible = false;

  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
    private store: GmsStore
  ) {}

  ngOnInit() {
    //subscribe to user permissions
    this.store.gmsUser$
      .pipe(filter(p => p != undefined))
      .subscribe(user => this.updateView(this.userService.hasPermission(this.gmsPermission, user?.roles)));
  }

  ngOnDestroy() {
    this.clearView();
  }  

  private updateView(show: boolean) {
    if (show && !this.visible) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    if (!show && this.visible) {
      this.clearView();
    }
    this.visible = show;
  }

  private clearView() {
    this.viewContainer.clear();
  }
}
