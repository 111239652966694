import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from './features/home/components/home.component';
import { NpsLoginComponent } from './features/home/components/login.component';
import { NpsLogoutComponent } from './features/home/components/logout.component';
import { NpsLayoutLiteComponent } from './features/layout/components/nps.layout-lite.component';
import { NpsLayoutComponent } from './features/layout/components/nps.layout.component';
import { NpsGuard } from "./services/nps-auth.service";
import { ProjectSpokesSpokeReplicateComponent } from "./features/project-spokes/components/project-spokes-spoke-replicate/project-spokes-spoke-replicate.component";
import { SpokeRequestApproveComponent } from "./features/project-spokes/components/spoke-request-approve/spoke-request-approve.component";

const routes: Routes = [
  {
    path: '', component: HomeComponent, pathMatch: 'full'
  },
  {
    path: 'nps', component: NpsLayoutComponent,
    canActivate: [NpsGuard],
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'projects', loadChildren: () => import('./features/projects/projects.module').then(m => m.ProjectsModule) },
      { path: 'project-spokes', loadChildren: () => import('./features/project-spokes/project-spokes.module').then(m => m.ProjectSpokesModule) },
    ]
  },
  {
    path: 'nps/project-spokes', component: NpsLayoutLiteComponent,
    children: [
      { path: 'replicate', canActivate: [NpsGuard], component: ProjectSpokesSpokeReplicateComponent },
      { path: 'requests/approvals/:mappingId', canActivate: [NpsGuard], component: SpokeRequestApproveComponent },
    ]
  },
  {
    path: 'nps/network-requests',
    canActivate: [NpsGuard],
    loadChildren: () => import('./features/network-requests/network-request.module').then(m => m.NetworkRequestModule)
  },
  {
    path: 'nps/firewall', component: NpsLayoutComponent,
    canActivate: [NpsGuard],
    loadChildren: () => import('./features/firewalls/firewall.module').then(m => m.FirewallModule)
  },
  {
    path: 'nps/login', component: NpsLayoutLiteComponent,
    children: [
      { path: '', component: NpsLoginComponent },
    ]
  },
  {
    path: 'nps/logout', component: NpsLayoutLiteComponent,
    children: [
      { path: '', component: NpsLogoutComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class NpsRoutingModule {}
