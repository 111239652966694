import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { HomeModule } from './features/home/home.module';
import { TmsLayoutModule } from './features/layout/tms.layout.module';
import { TmsRoutingModule } from './tms-routing.module';
import { LayoutService } from '../shared/services/layout.service';
import { TmsAuthService, TmsInterceptor } from './services/tms-auth.service';
import { TmsCommunicationService } from './services/tms-communication.service';


@NgModule({
  imports: [
    ButtonModule,
    MenubarModule,
    ConfirmDialogModule,
    ToastModule,

    TmsLayoutModule,
    HomeModule,

    TmsRoutingModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),

    TmsAuthService,
    TmsCommunicationService,

    { provide: HTTP_INTERCEPTORS, useClass: TmsInterceptor, deps: [TmsAuthService, LayoutService], multi: true },
  ]
})
export class TmsModule {}
