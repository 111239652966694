import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from './features/home/components/home.component';
import { GmsLayoutComponent } from "./features/layout/components/layout.component";
import { GmsLoginComponent } from "./features/home/components/login.component";
import { GmsLogoutComponent } from "./features/home/components/logout.component";
import { GmsLayoutLiteComponent } from "./features/layout/components/layout-lite.component";
import { GmsGuard } from "./services/gms-auth.service";

const routes: Routes = [
  {
    path: '', component: HomeComponent, pathMatch: 'full'
  },
  {
    path: 'gms', component: GmsLayoutComponent,
    canActivate: [GmsGuard],
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'governance-visualizer', loadChildren: () => import('../gms/features/governance-visualizer/governance-visualizer.module').then(m => m.GovernanceVisualizerModule) },
      { path: 'waivers', loadChildren: () => import('../gms/features/waivers/waiver.module').then(m => m.WaiverModule) },
     ]
  },
  {
    path: 'gms/login', component: GmsLayoutLiteComponent,
    children: [
      { path: '', component: GmsLoginComponent },
    ]
  },
  {
    path: 'gms/logout', component: GmsLayoutLiteComponent,
    children: [
      { path: '', component: GmsLogoutComponent },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class GmsRoutingModule {}
