import { Component, OnInit } from '@angular/core';
import { TmsStore } from '../../../services/tms.store';
import { UserService } from '../../../services/api/user.service';

@Component({
  selector: 'tms-menu',
  template: '<p-menu [model]="items" styleClass="w-full layout-menu border-none"></p-menu>'
})
export class TmsMenuComponent implements OnInit {

  items: any[] = [];

  allItems = [
    {
      label: 'Policies Management',
      icon: 'pi pi-fw pi-shield',
      //permission: 'GovernanceVisualizerGetUri'
      //routerLink: '/tms/policies',
    //  items: [{
    //    label: 'Assign AAD Role',
    //    icon: 'pi pi-fw pi-address-book',
    //    routerLink: '/gps/iam/aad-role-assign',
    //    //permission: 'ProjectGetList'
    //  }]
    },
    {
      label: 'Management Groups Management',
      icon: 'pi pi-fw pi-receipt',
      permission: ''
      //routerLink: '/tms/management-groups',
    }
  ];

  constructor(
    private store: TmsStore,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.store.tmsUser$
      .subscribe(user => {
        if (!user) {
          this.items = [];
        } else {
          //filters items based on permissions
          this.items = this.allItems
            .filter(i => !i.permission || this.userService.hasPermission(i.permission, user?.roles));
        //    .map(i => {
        //      i.items = i.items.filter(s => !s.permission || this.userService.hasPermission(s.permission, user?.roles));
        //      return i;
        //    });
        }
      });
  }
}
