import { Component } from '@angular/core';
import { ApplicationType } from '../../../../shared/models/application-type';
import { LayoutService } from '../../../../shared/services/layout.service';
import { PortalStore } from '../../../services/portal.store';
import { PortalCommunicationService } from '../../../services/portal-communication.service';
import { Tenant, TenantKey, getTenantById } from '../../../../shared/models/tenant';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  tenantList: { label: string; value: string }[] = []; // Dropdown options
  selectedTenant: { label: string; value: string } | null = null; // Selected tenant

  constructor(
    private communication: PortalCommunicationService,
    public layoutService: LayoutService,
    public portalStore: PortalStore
  ) { }

  ngOnInit() {
    this.portalStore.availableTenants$.subscribe(tenantIds => {

      this.tenantList = (tenantIds ?? []).map(tenantId => ({
        label: getTenantById(tenantId) || `Unknown (${tenantId})`,
        value: tenantId 
      }));

      const currentTenantKey = this.portalStore.current.tenant as TenantKey;

      if (currentTenantKey) {
        const currentTenantId = this.getTenantIdByKey(currentTenantKey); 

        const foundTenant = this.tenantList.find(t => t.value === currentTenantId);
        this.selectedTenant = foundTenant || null;
      }

    });

    this.portalStore.tenant$.subscribe(tenantKey => {
      if (tenantKey) {
        const tenantId = this.getTenantIdByKey(tenantKey);
        const foundTenant = this.tenantList.find(t => t.value === tenantId);
        this.selectedTenant = foundTenant || null;
      }
    });
  }

  login() {
    this.communication.userLogin$.next(ApplicationType.Portal);
  }

  logout() {
    this.communication.userLogout$.next();
  }

  switchTenant(selected: { label: string; value: string }) {
    const tenantKey = getTenantById(selected.value); 
    if (tenantKey && tenantKey !== this.selectedTenant?.value) {
      this.communication.switchTenant$.next(selected.value);
    }
  }

  private getTenantIdByKey(tenantKey: TenantKey): string | undefined {
    return Tenant[tenantKey];
  }
}
