<h1 class="text-center mt-5">TMS: Tenant Management Service</h1>

<div class="px-4 py-5 md:px-6 lg:px-8">
  <div class="grid justify-content-center">

    <a class="col-12 md:col-6 lg:col-4" routerLink="./" *tmsPermission="">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Policy</span>
            <div class="text-900 font-medium text-xl">Policies Management</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-shield text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>

    <a class="col-12 md:col-6 lg:col-4" routerLink="./" *tmsPermission="">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Management Groups</span>
            <div class="text-900 font-medium text-xl">Management Groups Management</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-receipt text-red-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>   


  </div>
</div>
