import { Component, ElementRef } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';

@Component({
  selector: 'tms-sidebar',
  template: '<tms-menu></tms-menu>'
})
export class TmsSidebarComponent {
    constructor(public layoutService: LayoutService, public el: ElementRef) { }
}

