export class Tenant {
    tenantId: string;
    displayName: string;
    countryCode?: string;
    domains?: string[];
    tenantCategory?: string;
  
    constructor(data: Partial<Tenant>) {
      this.tenantId = data.tenantId || '';
      this.displayName = data.displayName || '';
      this.countryCode = data.countryCode;
      this.domains = data.domains;
      this.tenantCategory = data.tenantCategory;
    }
  }
  
  export class TenantResponse {
    value: Tenant[];
  
    constructor(data?: any) {
      this.value = (data?.value || []).map((tenant: any) => new Tenant(tenant));
    }
  }
  