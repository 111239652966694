import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../../services/api/configuration.service';
import { GmsStore } from '../../../services/gms.store';
import { UserService } from '../../../services/api/user.service';
import { NotificationService } from '../../../../shared/services/notification.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  constructor(
    private configurationService: ConfigurationService,
    private userService: UserService,
    private store: GmsStore,
    private notify: NotificationService
  ) {
  }

  async ngOnInit() {
    //recheck permissions and user
    if (!this.store.current.permissions) {
      this.configurationService.getPermissions()
        .subscribe({
          next: (prm) => this.store.setPermissions(prm),
          error: (err) => {
            this.notify.error(err);
          }
        });
    }
    if (!this.store.current.gmsUser) {
      this.userService.userMeGet()
        .subscribe({
            next: (user) => {
              this.store.setGmsUser(user);
            },
            error: (err) => {
              this.notify.error(err);
            }
          });
    }
  }
}
