import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';

import { CommonModule } from '@angular/common';
import { AppLayoutModule } from '../../../portal/features/layout/app.layout.module';
import { GmsLayoutComponent } from './components/layout.component';
import { GmsMenuComponent } from './components/menu.component';
import { GmsSidebarComponent } from './components/sidebar.component';
import { GmsLayoutLiteComponent } from './components/layout-lite.component';
import { GmsProgressLayoutComponent } from './components/progress.layout.component';

@NgModule({
  declarations: [
    GmsMenuComponent,
    GmsSidebarComponent,
    GmsLayoutComponent,
    GmsLayoutLiteComponent,
    GmsProgressLayoutComponent
  ],
  imports: [
    CommonModule,
    AppLayoutModule,
    ButtonModule,
    MenuModule, 
    SidebarModule,
    RippleModule,
    RouterModule,
    ProgressBarModule
  ],
  exports: [GmsLayoutComponent]
})
export class GmsLayoutModule { }
