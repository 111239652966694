import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { TmsPermissionDirective } from './directives/tms-permission.directive';


@NgModule({
  declarations: [TmsPermissionDirective], // Export so it can be used in other modules
  imports: [
    CommonModule,
    ButtonModule,
    MenubarModule,
    ConfirmDialogModule,
    ToastModule
  ],
  exports: [TmsPermissionDirective] // Export so it can be used in other modules
})
export class TmsSharedModule { }
