<h1 class="text-center mt-5">GMS: Governance Management Service</h1>

<div class="px-4 py-5 md:px-6 lg:px-8">
  <div class="grid justify-content-center">

    <a class="col-12 md:col-6 lg:col-4" routerLink="./governance-visualizer" *gmsPermission="'UserRole:VisualizerAdmin.VWGITC,UserRole:VisualizerAdmin.CARIAD,UserRole:VisualizerAdmin.POWERCO,UserRole:VisualizerAdmin.VWGPROD,UserRole:VisualizerAdmin.VWGST'">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Governance</span>
            <div class="text-900 font-medium text-xl">Governance Visualilzer</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-sitemap text-green-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>

    <a class="col-12 md:col-6 lg:col-4" routerLink="./waivers/list">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Waivers</span>
            <div class="text-900 font-medium text-xl">Waivers Management</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-lock-open text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>

    <!--<a class="col-12 md:col-6 lg:col-4" routerLink="./">
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Subscriptions</span>
          <div class="text-900 font-medium text-xl">Subscriptions Management</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
          <i class="pi pi-briefcase text-blue-500 text-xl"></i>
        </div>
      </div>
    </div>
  </a>

  <a class="col-12 md:col-6 lg:col-4" routerLink="./">
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Consumption</span>
          <div class="text-900 font-medium text-xl">Consumption Management</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
          <i class="pi pi-gauge text-gray-500 text-xl"></i>
        </div>
      </div>
    </div>
  </a>

  <a class="col-12 md:col-6 lg:col-4" routerLink="./">
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Contract</span>
          <div class="text-900 font-medium text-xl">Contract Management</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
          <i class="pi pi-address-book text-red-500 text-xl"></i>
        </div>
      </div>
    </div>
  </a>

    <a class="col-12 md:col-6 lg:col-4" routerLink="./">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Users</span>
            <div class="text-900 font-medium text-xl">Users Management</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-primary-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-users text-green-500 text-xl"></i>
          </div>
        </div>
      </div>
    </a>-->

  </div>
</div>
