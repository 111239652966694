import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PortalStore } from '../../../../services/portal.store';
import { getTenantById } from '../../../../../shared/models/tenant';
import { PortalAuthService } from '../../../../services/portal-auth.service';

@Component({
    selector: 'app-select-tenant',
    templateUrl: './select-tenant.component.html'
})
export class SelectTenantComponent {
    tenantList: { id: string; name: string }[] = [];

    constructor(
        private store: PortalStore, 
        private router: Router,
        private authService: PortalAuthService
    ) {}

    ngOnInit() {
        console.log("SelectTenant page init start");
        this.store.availableTenants$.subscribe(tenantIds => {
            if (tenantIds && tenantIds.length > 0) {
                this.tenantList = tenantIds.map(tenantId => ({
                    name: getTenantById(tenantId) || `Unknown`,
                    id: tenantId
                }));
                console.log("Loaded tenant list:", this.tenantList);
            } else {
                console.warn("No available tenants found.");
            }
        });
    }

    async selectTenant(tenantId: string) {
        console.log("Tenant selected:", tenantId);
    
        const tenantKey = getTenantById(tenantId);
        this.store.setTenant(tenantKey);
    
        await this.authService.performTenantLogin(tenantId);
    
        const redirectUrl = localStorage.getItem('originalUrl') || '/';
        localStorage.removeItem('originalUrl');
        console.log("Redirecting user back to:", redirectUrl);
        this.router.navigate([redirectUrl]);
    }
    
}
