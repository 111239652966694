import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Subject, takeUntil } from 'rxjs';
import { PortalStore } from '../../../../portal/services/portal.store';
import { TenantKey, getTenantKeys } from '../../../../shared/models/tenant';
import { NpsAuthService } from '../../../services/nps-auth.service';
import { PortalCommunicationService } from '../../../../portal/services/portal-communication.service';

@Component({
  selector: 'nps-login',
  templateUrl: './login.component.html',
})
export class NpsLoginComponent implements OnInit, OnDestroy {

  public message = '';

  private portalLoggedIn$ = new Subject<boolean>();
  private tenantFound$ = new Subject<boolean>();
  private npsAuthInited$ = new Subject<boolean>();

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private npsAuth: NpsAuthService,
    private portalStore: PortalStore,
    private route: ActivatedRoute,
    private portalCommunication: PortalCommunicationService
  ) {

    let redirectTo = this.route.snapshot.queryParams['redirect'] ?? '/nps';

    //TODO: handle errors

    this.portalLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (loggedIn) => {
        this.message = 'Please wait while we are logging you in...';
        await this.doFindTenant();
      });

    this.tenantFound$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (tenantFound) => {
        this.message = `Tenant ${this.portalStore.current.tenant} found!`;
        await this.doAuthInit();
      });

    this.npsAuthInited$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (npsAuthInited) => {
        //window.location.href = redirectTo;     
        this.router.navigateByUrl(redirectTo);
      });
  }

   async ngOnInit() {

     this.message = 'Logging in to Portal ...';
     this.doPortalLogin();
     
     //check if tenant is set if not find tenant

     //init nps auth service for selected tenant

     //if tenant not found redirect to error page

     //redirect to requested page
  }

  private doPortalLogin() {
    //check if portal auth completed if not request login
    if (!this.portalStore.current.user) {
      this.portalCommunication.userLogin$.next(this.npsAuth.store.applicationType);
      this.portalStore.user$
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (user) => {
          if (user) {
            this.portalLoggedIn$.next(true);
          }
        });
    } else {
      this.portalLoggedIn$.next(true);
    }
  }

  private async doFindTenant() {
    const account = this.portalStore.current.user!;
    if (!this.portalStore.current.tenant) {
      this.findTenant(account)
        .then(async (tenant) => {
          await this.npsAuth.initForTenant(this.portalStore.current.tenant!);

          this.tenantFound$.next(true);
        })
        .catch((error) => {
          this.message = "Tenant not found + redirect to error page";
          console.error("Tenant not found", error);
          this.portalStore.setTenant(undefined);
          this.router.navigateByUrl('/error/tenant-notfound');
        });

    } else {
      const tenant = this.portalStore.current.tenant;
      try {
        console.log("findTenant: Tenant already selected", tenant);
        await this.checkTenant(tenant as TenantKey, account);
        console.log("findTenant: Tenant approved", tenant);

        this.tenantFound$.next(true);
      } catch (error) {
        console.log("findTenant: Tenant not available", tenant, error);
        this.portalStore.setTenant(undefined);
        this.doFindTenant();
      }
    }
  }

  private async findTenant(account: AccountInfo): Promise<TenantKey | undefined> {
    return new Promise(async (resolve, reject) => {
      console.log('findTenand in ', getTenantKeys())
      for (var tenant of getTenantKeys()) {
        try {

          console.log("findTenant: Check Tenant", tenant);
          await this.checkTenant(tenant as TenantKey, account);
          console.log("findTenant: Tenant found", tenant);

          resolve(tenant as TenantKey);

          return;
        } catch (error) {
          console.log("findTenant: Tenant not available", tenant, error);
          //continue;
        }
      }

      reject(undefined);
    });
  }

  private async checkTenant(tenant: TenantKey, account: AccountInfo): Promise<AuthenticationResult | undefined> {
    return new Promise(async (resolve, reject) => {
      //const result = await this.npsAuth.service.instance.acquireTokenSilent({ account: account, scopes: this.npsAuth.configuration.getMsalScopes() });
      let request: any;
      try {
        await this.npsAuth.initForTenant(tenant);
        request = {
          account: account,
          scopes: this.npsAuth.configuration.getMsalScopes(),
          authority: this.npsAuth.configuration.msalAuthority
        };

        //const result = await this.npsAuth.service.instance.ssoSilent(request);
        const result = await this.npsAuth.service.instance.acquireTokenSilent(request);
        resolve(result);
      } catch (err) {
        console.log('CheckTenant. TokenSilent Catch', err);
        if (err instanceof InteractionRequiredAuthError) {
          const authError = err as InteractionRequiredAuthError;
          if (authError.errorNo === '16000' && authError.errorMessage.indexOf('does not exist in tenant') > -1) {
            console.log(authError.errorMessage);
          } else {
            try {
              const loginResponse = await this.npsAuth.service.instance.loginPopup(request)
                .then(response => resolve(response))
                .catch(error => {
                  // handle error
                  console.log("checkTenant error on login popup", error);
                  reject();
                });
            } catch (error) {
              console.log("checkTenant exception on login popup", error);
            }
          }
        }
        reject();
      }
    });
  }

  private async doAuthInit() {
    return new Promise(async (resolve, reject) => {
      if (this.npsAuth.inited$.getValue()) {
        //already inited on find tenant
        this.npsAuthInited$.next(true);
        resolve(true);
      } else {
        //this.npsAuth.inited$.subscribe(async (inited) => {
        //  resolve(true);
        //});
        await this.npsAuth.initForTenant(this.portalStore.current.tenant!);
        resolve(true);
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
