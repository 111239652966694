import { Component, OnInit } from '@angular/core';
import { GmsStore } from '../../../services/gms.store';
import { UserService } from '../../../services/api/user.service';

@Component({
    selector: 'gms-menu',
  template: '<p-menu [model]="items" styleClass="w-full layout-menu border-none"></p-menu>'
})
export class GmsMenuComponent implements OnInit {

  items: any[] = [];

    allItems = [
      {
        label: 'Governance Visualizer',
        icon: 'pi pi-fw pi-sitemap',
        routerLink: '/gms/governance-visualizer',
        permission: 'UserRole:VisualizerAdmin.VWGITC,UserRole:VisualizerAdmin.CARIAD,UserRole:VisualizerAdmin.POWERCO,UserRole:VisualizerAdmin.VWGPROD,UserRole:VisualizerAdmin.VWGST',
        items: [{
          label: 'Cariad',
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/gms/governance-visualizer/view/CARIAD',
          permission: 'UserRole:VisualizerAdmin.CARIAD'
        }, {
          label: 'VWGPROD',
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/gms/governance-visualizer/view/VWGPROD',
          permission: 'UserRole:VisualizerAdmin.VWGPROD'
        }, {
          label: 'PowerCo',
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/gms/governance-visualizer/view/POWERCO',
          permission: 'UserRole:VisualizerAdmin.POWERCO'
        }, {
          label: 'VWGST',
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/gms/governance-visualizer/view/VWGST',
          permission: 'UserRole:VisualizerAdmin.VWGST'
        }, {
          label: 'VWGITC',
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/gms/governance-visualizer/view/VWGITC',
          permission: 'UserRole:VisualizerAdmin.VWGITC'
        }]
      },
      {
        label: 'Waivers',
        icon: 'pi pi-fw pi-lock-open',
        routerLink: '/gms/waivers',
        permission: '',
        items: [{
          label: 'List',
          icon: 'pi pi-fw pi-lock-open',
          routerLink: '/gms/waivers/list',
          permission: ''
        }
        //, {
        //  label: 'Create',
        //  icon: 'pi pi-fw pi-plus',
        //  routerLink: '/gms/waivers/list',
        //  queryParams: { action: 'create' },
        //  permission: ''
        //}
        ]
      }
    ];

  constructor(
    private store: GmsStore,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.store.gmsUser$
      .subscribe(user => {
        if (!user) {
          this.items = [];
        } else {
          //filters items based on permissions
          this.items = this.allItems
            .filter(i => !i.permission || this.userService.hasPermission(i.permission, user?.roles))
            .map(i => {
              i.items = i.items.filter(s => !s.permission || this.userService.hasPermission(s.permission, user?.roles));
              return i;
            });
        }
      });
  }
}
