import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';

import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home.component';
import { CardModule } from 'primeng/card';
import { TmsLogoutComponent } from './components/logout.component';
import { TmsLoginComponent } from './components/login.component';
import { TmsSharedModule } from '../../tms-shared.module';

@NgModule({
  declarations: [
    HomeComponent,
    TmsLoginComponent,
    TmsLogoutComponent
  ],
  imports: [
    TmsSharedModule,
    CardModule,
    RouterModule,
    CommonModule,
    ButtonModule,
    RouterModule
  ]
})
export class HomeModule {}

