import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from './features/home/components/home.component';
import { LoginFailedComponent } from './features/home/components/login-failed.component';
import { MemberLoginComponent } from './features/home/components/member-login.component';
import { NotfoundComponent } from './features/home/components/notfound.component';
import { TenantNotfoundComponent } from './features/home/components/tenant-notfound.component';
import { PortalLayoutComponent } from './features/layout/components/portal.layout.component';
import { PortalGuard } from "./services/portal-auth.service";
import { SelectTenantComponent } from "./features/layout/components/select-tenant-component/select.tenant.component";

const routes: Routes = [
  {
    path: '', component: PortalLayoutComponent,
    pathMatch: 'full',
    canActivate: [PortalGuard],
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  }, {
    path: 'auth', component: HomeComponent,
    pathMatch: 'full',
  },
  { 
    path: 'select-tenant', 
    component: PortalLayoutComponent,
    children: [{
      path: '', 
      component: SelectTenantComponent,
    }
    ]
  },
  {
    path: 'gpa', component: NotfoundComponent,
    pathMatch: 'full',
  },
  {
    path: 'member-login', component: MemberLoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'error', component: PortalLayoutComponent,
    children: [
      { path: 'notfound', component: NotfoundComponent },
      { path: 'login-failed', component: LoginFailedComponent },
      { path: 'tenant-notfound', component: TenantNotfoundComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes), 
  ],
  exports: [RouterModule]
})
export class PortalRoutingModule {}
