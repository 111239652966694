
import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { TenantKey } from '../../shared/models/tenant';
import { tmsEnvironment } from '../environments/environment';
import { UserMeDto } from './api/user.model';
import { RequestPermissionsType } from './api/configuration.model';
import { IStore, IStoreProps } from '../../shared/services/store.interface';
import { ApplicationType } from '../../shared/models/application-type';

const tmsStoreKey = 'tms.store';
interface TmsProps extends IStoreProps {
    user?: AccountInfo,
    tmsUser?: UserMeDto ;
    tenant?: TenantKey;
    permissions?: { [key: string]: RequestPermissionsType };
}

const tmsStore = createStore(
    { name: 'user' },
    withProps<TmsProps>({})
);

//save in local storage
export const persist = persistState(tmsStore, {
    key: tmsStoreKey,
    storage: localStorageStrategy,
});

tmsStore.subscribe((state) => console.log(state));

@Injectable({ providedIn: 'root' })
export class TmsStore implements IStore {

    store$ = tmsStore;

    user$ = tmsStore.pipe(select((state) => state.user));
    tenant$ = tmsStore.pipe(select((state) => state.tenant));
    tmsUser$ = tmsStore.pipe(select((state) => state.tmsUser));

    constructor() {
    }

    get current(): TmsProps {
        return tmsStore.getValue();
    }

    get apiUrl(): string {
        return this.current.tenant ? tmsEnvironment.api[this.current.tenant]!.url : '';
    }

  get applicationType(): ApplicationType {
    return ApplicationType.Tms;
  }

    setUser(user: AccountInfo | undefined) {
        tmsStore.update((state) => ({
            ...state,
            user
        }));
    }

    setTmsUser(tmsUser: UserMeDto | undefined) {
      tmsStore.update((state) => ({
        ...state,
        tmsUser
      }));
    }

    setPermissions(permissions: { [key: string]: RequestPermissionsType } | undefined) {
      tmsStore.update((state) => ({
        ...state,
        permissions
      }));
    }

    setTenant(tenant: TenantKey | undefined) {
        if (tenant && !tmsEnvironment.api[tenant])
            throw new Error(`There is no configuration for tenant ${tenant}`);

        tmsStore.update((state) => ({
            ...state,
            tenant
        }));
    }

    cleanUser() {
        console.log("TmsStore.clean")
        //leave token
        this.setUser(undefined);
        this.setTmsUser(undefined);
    }
}
