import { Component, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { GmsSidebarComponent } from "./sidebar.component";

@Component({
    selector: 'gms-layout',
    templateUrl: './layout.component.html'
})
export class GmsLayoutComponent /*extends AppLayoutComponent*/ {

  @ViewChild(GmsSidebarComponent) appSidebar!: GmsSidebarComponent;

  constructor(layoutService: LayoutService) {
    layoutService.state.sidebarMenuActive = true;

    layoutService.config.secondLogoText = 'Governance Management';
    layoutService.config.secondLogoIcon = 'pi-shield';
    layoutService.config.secondLogoUrl = '/gms';

    layoutService.config.dashboardBackground = false;
  }

}
